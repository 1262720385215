// This file can be replaced during build by using the `fileReplacements` array.color"
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDNcWa-jVsrH4Tf4YGhCpOMHKTsg405meo",
    authDomain: "ionic-orderexpress.firebaseapp.com",
    databaseURL: "https://ionic-orderexpress-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ionic-orderexpress",
    storageBucket: "ionic-orderexpress.appspot.com",
    messagingSenderId: "425406633393",
    appId: "1:425406633393:web:18e8c4878e63a33f1a1990",
    measurementId: "G-S3EZXKGRHF"
  }
};

export interface product {
    color?: string,
    drucker?: string,
    groesse?: number,
    index?: number,
    lock?: boolean,
    lockColor?: string,
    lockItem?: string,
    name?: string,
    preis?: number,
    token?: string,
    type?: string
  }

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
