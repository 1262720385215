<div class="container">
  <h3>Veranstaltung auswählen:</h3>
  <div class="row">
    <div class="col-4 col-md-3" col-lg-2 *ngFor="let loc of myLocations">
      <div class="card m-0 mb-1" [ngClass]="this.selected == loc.key ? 'bg-warning':''">
        <div class="card-body p-2" style="font-size: 15px;"  (click)="onChange(loc)" aria-label="Default select example">
          {{loc.name}}
        </div>
      </div>
    </div>
  </div>
</div>