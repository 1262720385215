<app-header></app-header>
<div class="container">
  <div class="row">
    <div class="col-12 col-sm-6 col-lg-4">
      <div class="card">
        <div class="card-head kopfCenter">
            Gedruckte Bons
        </div>
        <div class="card-body umsatz">
          {{bonNR}}
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4" hidden>
      <div class="card">
        <div class="card-head kopfCenter">
          Aktive Kellner
        </div>
        <div class="card-body umsatz">
          4
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4" hidden>
      <div class="card">
        <div class="card-head kopfCenter">
          Umsatz
        </div>
        <div class="card-body umsatz">
          252,55 €
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-head kopfCenter">
          Aktive Drucker
        </div>
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4" *ngFor="let druck of drucker |keyvalue">
                <div class="card">
                  <div class="card-head kopfCenter">{{druck.value.name}}</div>
                  <div class="grid p-2">
                    <div class="row">
                      <div class="col-12 col-xl-6">
                        <div class="grid p-0">
                          <div class="row">
                            <div class="col-8 klein dick">mm:</div>
                            <div class="col-4 klein end">{{druck.value.mm}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-xl-6">
                        <div class="grid p-0">
                          <div class="row">
                            <div class="col-8 klein dick">dpi:</div>
                            <div class="col-4 klein end">{{druck.value.zdpi}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-xl-6">
                        <div class="grid p-0">
                          <div class="row">
                            <div class="col-8 klein dick">Zeiche/Zeile:</div>
                            <div class="col-4 klein end">{{druck.value.zpz}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-xl-6">
                        <div class="grid p-0">
                          <div class="row">
                            <div class="col-8 klein dick">lock:</div>
                            <div class="col-4 klein end">{{druck.value.lock}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>
