<section class="h-100">
  <div class="container h-100">
    <div class="row justify-content-sm-center h-100">
      <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
        <div class="text-center my-5">
          <img src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo.svg" alt="logo" width="100">
        </div>
        <div class="card shadow-lg">
          <div class="card-body p-5">
            <h1 class="fs-4 card-title fw-bold mb-4">Passort zurücksetzen!</h1>
          <div class="form-floating mb-3">
            <input (focus)="reseted = true" type="email" class="form-control" id="floatingPassword" placeholder="Password" id="email" #email>
            <label for="floatingPassword">Email</label>
          </div>
          <div class="d-grid">
            <button class="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-0" type="button" (click)="authService.ForgotPassword(email.value); reseted = false;">absenden</button>
          </div>
          <div class="text-success mb-2 mt-4" [hidden]="reseted">
            Es wurde eine Email zum zurücksetzen des Passworts an <b>{{email.value}}</b> gesendet.
          </div>
          </div>

          <div class="card-footer py-3 border-0">
            <div class="text-center">
              Zurück zu <a  routerLink="/sign-in" class="text-dark">Login</a>
            </div>
          </div>

        </div>
        <div class="text-center mt-5 text-muted">
          Copyright &copy; 2017-2021 &mdash; Your Company
        </div>
      </div>
    </div>
  </div>
</section>
