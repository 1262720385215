import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm:FormGroup;

  constructor(
    public authService: AuthService,
    private router:Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
     this.loginForm = this.formBuilder.group({
      email: ['',[Validators.required, Validators.email]],
      pwd: ['',[Validators.required, Validators.minLength(6)]]
    });
    if(this.authService.isLoggedIn === true){
      this.router.navigate(['/home'])
    }
   }

    pwd = true;
    email = true;
    emailContent = 'Die eMail Adresse ist ungültig!';
    pwdContent = '';
    submitted = false;
    emailCorrect:boolean;
    pwCorrect = true;
    pwShort = false;


  get f() { return this.loginForm.controls; }
  async SignIn(value){
    this.pwd = true;
    this.email = true;
    console.log(value.email,value.pwd)
    await this.authService.SignIn(value.email,value.pwd).then((res)=>{
      console.log(res);
      if(res == 'true'){
        console.log('res == true!!!!');
        this.emailCorrect = true;
        this.pwCorrect = true;
        setTimeout(()=>{
          this.router.navigate(['/home']);
        },1000)

      }else{

      }
    })
    .catch((res)=>{
      console.log(res);
      if(res == "auth/invalid-email"){
        console.log('Email falsches Format');
        this.email = false;
        this.emailContent= "Die eMail Adresse ist ungültig!";
      }
      if(res == "auth/user-not-found"){
        console.log('User nicht registriert');
        this.email = false;
        this.emailContent= "Die eMail Adresse ist nicht registriert.";
        this.emailCorrect= false;
      }
      if(res == "auth/wrong-password"){
        console.log('Falsches Passwort');
        this.pwd = false;
        this.pwCorrect = false;
        this.emailCorrect = true;
        this.pwdContent = 'Das angegebene Passwort ist falsch!'
      }
    })
  }

  submitForm() {
    this.submitted = true;
    if (!this.loginForm.valid) {
      console.log(this.loginForm.controls.pwd.errors
        );
      let x = this.loginForm.controls.pwd.errors && this.loginForm.controls.pwd.errors.minlength;
      console.log(x);
      if(x){
        this.pwShort = true;
      }else{
        this.pwShort = false;
      }
      // this.message = 'Bitte alle Felder ausfüllen!'
      // if(this.loginForm.email)
      return false;
    } else {
      console.log(this.loginForm.value);
      this.SignIn(this.loginForm.value);
    }
  }

}
