import { User } from './../shared/services/user';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { stringify } from 'querystring';
import { DbService } from '../services/db.service';
import { DatePipe, DecimalPipe } from '@angular/common';





@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})


export class UserComponent implements OnInit {

  users = [];
  form = this.fb.group({
    token: ['',[Validators.required,Validators.minLength(6),]],
    name: ['',[Validators.required, Validators.minLength(3)]],
    vorname: ['',[Validators.required, Validators.minLength(3)]],
  });

  hash:string = '';
  loc = (JSON.parse(localStorage.getItem('location'))).key;

  constructor(
    public fb: FormBuilder,
    public db:DbService,
    private number:DecimalPipe,
    private time:DatePipe
  ){
  }

  async ngOnInit() {
    await this.getUsers();
    // this.kellner();
  };

  openModal(data){
    console.log(data);
    const modalHead = document.getElementById('modalHead');
    const modalBody = document.getElementById('modalBody');
    const modalFooter = document.getElementById('modalFooter');

    if(modalHead != null){
      modalHead.innerHTML= data.name+' '+data.vorname;
    }
    if(modalBody != null){
      let x = '<div class="container">';
      x += '<div class="row bs-center bs-button"><div class="col-2"><b>NR.</b></div><div class="col-2"><b>Tisch</b></div><div class="col-5"><b>Bestellung</b></div><div class="col-3 last"><b>Betrag</b></div></div>'
      Object.keys(data['rechnungen']).forEach(item=>{
        console.log(data['rechnungen'][item]);
        console.log(data['rechnungen'][item]);
        let prod = '';
        let first = true;
        Object.keys(data['rechnungen'][item]['produkte']).forEach(key=>{
          let einzel = data['rechnungen'][item]['produkte'][key];
          // console.log(einzel);
          if(first) {prod = einzel.anzahl+'x '+einzel.name; first=false;}
          else prod += ', '+einzel.anzahl+'x '+einzel.name;
        })
        if(prod.length >= 20) prod = prod.substring(0,20)+'...';
        let betrag = data['rechnungen'][item]['betrag'];
        betrag = this.number.transform(betrag, '1.2-2');
        let timeStamp = data['rechnungen'][item]['setTime']
        x += '<div class="row rowPopup"><div class="col-1">'+data['rechnungen'][item]['Rechnungsnummer']+'</div><div class="col-2">'+this.time.transform(timeStamp,'dd.MM.YY HH:mm:ss')+'</div><div class="col-1">'+data['rechnungen'][item]['tabel']+'</div><div class="col-5">'+prod+'</div><div class="col-3 last">'+betrag+' €</div></div>'
      });
      x += '</div>'
      modalBody.innerHTML= x;

    }
  }
  closeModal(){
    const modalDiv = document.getElementById('myModal');
    if(modalDiv != null){
      modalDiv.style.display = 'none';
    }
  }

  async getUsers(){
    return new Promise(resolve=>{

      this.db.getMyUsers(this.loc).snapshotChanges().subscribe((res)=>{
        let x = 0;
        //////console.log(res);
        this.users = [];
        res.forEach(item=>{
          x++;
          let content = item.payload.toJSON();
          this.users.push({key:item.key, name:content['name'],vorname:content['vorname'],aktiv:(content['active'] == undefined ? 'null' : content['active']),umsatz:0 });
          if(x == res.length)resolve(true);
        });
        ////console.log(this.users);
        // this.users = this.users.sort((a,b)=>a['aktiv'] - b['aktiv']);

        this.kellner().then(()=>{
          ////console.log('sorted')
          this.sortData('text');
        });
      })
    })

  }

  onSubmit(){
    if(this.form.valid){
      this.db.addKellner(this.form.value,this.loc);
    }
  }
  async newHash(){
    let msg = this.makeid(8);
      this.form.setValue({
        name:'',
        vorname:'',
        token:msg
    });

  }
  makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789?!';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
      };
      return result;
  }
  kellnerums = {};

  async kellner(){
    await new Promise((resolve)=>{
      let x = 0;
      this.users.forEach(item=>{
        x++;
        // ////console.log('97:-->',x);
        this.kellnerums[item.key] = {};
        this.kellnerums[item.key]['umsatz'] = 0;
        if(this.users.length == x) resolve(true);
      })
    })
    // ////console.log('Vor return');
    return new Promise((resolve)=>{
      this.db.getKellnerumsätze(this.loc).snapshotChanges().subscribe((res)=>{
        let xy = 0;
        ////console.log(res);
        if(res.length == 0) resolve(true);
        res.forEach((item)=>{
          xy++;
          // ////console.log(xy,res.length);
          let umsaetze = item.payload.toJSON();
          ////console.log(item.key,umsaetze);
          ////console.log(this.users);

          let x = 0;
          let umsatz = 0;
          Object.keys(umsaetze).forEach((key)=>{
            x++;
            umsatz = umsatz + umsaetze[key]['betrag'];
            if(x == Object.keys(umsaetze).length){
              // this.kellnerums[item.key] = {};
              // this.kellnerums[item.key]['umsatz'] = umsatz;
              this.users.forEach((res,index) =>{
                if(res.key == item.key){
                  this.users[index].umsatz = umsatz;
                  this.users[index]['rechnungen'] = umsaetze;
                }
              })
            }
          })
          if(xy == res.length)resolve(true);
        });
        ////console.log(this.kellnerums);
        this.sortData('text');
      })
    })

  }

  lockKellner(status,uid){
    ////console.log(status,'status');
    ////console.log(uid,'uid')
    this.db.lockKellner(status,uid,this.loc)
  }
  deleteKellner(uid){
    if(this.kellnerums[uid]['umsatz'] == 0){
      this.db.deleteKeller(uid,this.loc)
    }
  }

  sortData(_case) {
    switch (_case) {
      case 'number':
        this.users.sort((a, b) => {
          return a.position - b.position;
        });
        break;
      case 'text':
        this.users.sort((a, b) => {
          // ////console.log(a.aktiv,b.aktiv)
          if(a.umsatz == undefined || b.umsatz == undefined) return -1;
          var typeA = a.umsatz,
            typeB = b.umsatz;
          if (typeA < typeB)
            //sort string ascending
            return -1;
          if (typeA > typeB) return 1;
          return 0; //default return value (no sorting)
        }).sort((a, b) => {
          // ////console.log(a.aktiv,b.aktiv)
          if(a.aktiv == undefined || b.aktiv == undefined) return -1;
          var typeA = a.aktiv.toLowerCase(),
            typeB = b.aktiv.toLowerCase();
          if (typeA < typeB)
            //sort string ascending
            return -1;
          if (typeA > typeB) return 1;
          return 0; //default return value (no sorting)
        }).reverse();
    }
  }

}
