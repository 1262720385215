  <section class="h-100">
    <div class="container h-100">
      <div class="row justify-content-sm-center h-100">
        <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
          <div class="text-center my-5">
            <img src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo.svg" alt="logo" width="100">
          </div>
          <div class="card shadow-lg">
            <div class="card-body p-5">
              <h1 class="fs-4 card-title fw-bold mb-4 justify-text-sm-center">Email bestätigen!</h1>
              <div class="formGroup" *ngIf="authService.userData as user">
                <p class="text-center">Wir haben dir eine bestätigunsemail an <strong>{{user.email}}</strong> gesendet.</p>
                <p class="text-center">Bitte überprüfe deinen Posteingang oder versende den Bestätigunslink erneut!</p>
              </div>
              <div class="d-grid">
                <button class="btn btn-lg btn-primary mb-2 mt-3" type="button">
                  Email nochmals versenden!
                </button>
              </div>
            </div>

          <div class="text-center mt-5 text-muted">
            Copyright &copy; 2023 &mdash; OrderExpress
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
