<section class="h-100">
  <div class="container h-100">
    <div class="row justify-content-sm-center h-100">
      <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
        <div class="text-center my-5">
          <a href="/sign-in"><img src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo.svg" alt="logo" width="100"></a>

        </div>
        <div class="card shadow-lg">
          <div class="card-body p-5">
            <h1 class="fs-4 card-title fw-bold mb-4">Registrieren</h1>
            <form method="POST" class="needs-validation" novalidate="" autocomplete="off">
              <div class="mb-3">
                <label class="mb-2 text-muted" for="email">E-Mail Address</label>
                <input id="email" type="email" class="form-control" name="email" value="" required autofocus #email required>
                <div class="invalid-feedback">
                  Ungültige EMail!
                </div>
              </div>

              <div class="mb-3">
                <label class="text-muted" for="password">Passwort</label>
                <input id="password" type="password" class="form-control" name="password" #password required>
                <div class="invalid-feedback">
                  Passwort wird benötigt!
                </div>
              </div>
              <div class="mb-3">
                <label class="text-muted" for="password">Vorname</label>
                <input id="vorname" type="text" class="form-control" name="vorname" #vorname required>
                <div class="invalid-feedback">
                  Bitte Vorname eingeben!
                </div>
              </div>
              <div class="mb-3">
                <label class="text-muted" for="password">Nachname</label>
                <input id="nachname" type="text" class="form-control" name="nachname" #nachname required>
                <div class="invalid-feedback">
                  Bitte Nachname eingeben!
                </div>
              </div>
              <div>
              </div>
            </form>
          </div>
          <div class="card-footer py-3 border-0">
            <div class="d-grid gap-2">
              <button type="button" class="btn btn-primary "(click)="authService.SignUp(email.value, password.value, vorname.value, nachname.value)">kostenlos registrieren</button>
              <!-- <button class="btn btn-primary" type="button">Button</button> -->
            </div>
          </div>
        </div>
        <div class="text-center mt-4 text-muted">
          Copyright &copy; 2023 &mdash; OrderExpress
        </div>
      </div>
    </div>
  </div>
</section>
