import { Component, OnInit, HostListener } from '@angular/core';
import { DbService } from '../services/db.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {


  loc = 'pqnCQ2KCRJx3zN2Ln6UPPLSj';
  drucker = {};
  bonNR=0;

    constructor(
      private db:DbService
    ) {
      this.loc = ((JSON.parse(localStorage.getItem('location'))));
      if(this.loc){
        this.loc = this.loc['key'];
      }

      console.log(this.loc);
    }
  async ngOnInit() {
    console.log(this.loc);
    this.printers();
    this.allBons();
  }

  printers(){
    this.db.getPrinterData(this.loc).snapshotChanges().subscribe((res)=>{
      res.forEach((item)=>{
        let payload = item.payload.toJSON();
        console.log(payload);
        this.drucker[item.key] = payload;

      })
    })
  }

  allBons(){
    if(this.loc){
      this.db.getAllMyBons(this.loc).snapshotChanges().subscribe(res=>{
        this.bonNR = 0;
        res.forEach(item=>{
          let payload = item.payload.toJSON();
          this.bonNR = this.bonNR + payload['number'];
        })
      })
    }
  }


}
