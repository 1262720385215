<div class="body">
<h1>Benutzerverwaltung
</h1>
<div class="container">
  <div class="row">
    <div class="col">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-4 col-xl-3" *ngFor="let user of users">
            <div class="card padd button" (click)="openModal(user)" data-bs-toggle="modal" data-bs-target="#exampleModal" [ngClass]="user.aktiv == 'true' ? 'aktiv': (user.aktiv == 'false' ?  'inaktiv' : 'null')">
              <div class="card-head cardHead" *ngIf="users.length != 0">
                <div class="token" *ngIf="kellnerums[user.key] !== undefinied">
                  {{user.key.length >= 21 ? 'Admin': user.key.length >= 10 ? 'Station' :'Kellner'}}
                </div>
                <div class="icons" *ngIf="kellnerums[user.key] !== undefinied">
                  <i type="button" [ngClass]="{'bi-trash-fill hide': kellnerums[user.key]['umsatz'] > 0, 'bi-trash-fill trash': user.umsatz === 0}" (click)="deleteKellner(user.key)"></i>
                </div>
              </div>
              <div class="card-body cardBody">
                <div class="noUsers" *ngIf="users.length == 0">Noch Keine Benutzer für dieses Projekt angelegt!</div>
                <div class="block">
                  <div class="haupt" *ngIf="kellnerums[user.key] !== undefinied">
                    Name:
                  </div>
                  <div class="neben" *ngIf="kellnerums[user.key] !== undefinied">
                    {{user.name}} {{user.vorname}}
                  </div>
                </div>
                <div class="block">
                  <div class="haupt" *ngIf="kellnerums[user.key] !== undefinied">
                    Status:
                  </div>
                  <div class="neben" *ngIf="kellnerums[user.key] !== undefinied">
                    {{user.aktiv}}
                  </div>
                </div>
                <div class="block">
                  <div class="haupt" *ngIf="kellnerums[user.key] !== undefinied">
                    Token:
                  </div>
                  <div class="neben" *ngIf="kellnerums[user.key] !== undefinied">
                    {{user.key}}
                  </div>
                </div>
                <div class="block">
                  <div class="haupt" *ngIf="kellnerums[user.key] !== undefinied">
                    Umsatz:
                  </div>
                  <div class="haupt" *ngIf="kellnerums[user.key] !== undefinied">
                    {{user.umsatz | number : '1.2-2'}} €
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" *ngFor="let user of users" >
        <div class="card-head" *ngIf="users.length != 0">
        <h3>
          Registrierte Benutzer:
        </h3>
        </div>
        <div class="card-body">
          <div class="noUsers" *ngIf="users.length == 0">Noch Keine Benutzer für dieses Projekt angelegt!</div>
          <div *ngIf="users.length != 0">
            <div class="container">
              <div class="row">
                <div class="col-2 headline">
                  Umsatz
                </div>
                <div class="col-2 headline">
                  TOKEN
                </div>
                <div class="col-3 headline">
                  Name
                </div>
                <div class="col icons headline">
                  Funktionen
                </div>
              </div>
              <div class="row align-items-center users-row" *ngFor="let user of users" >
                <div class="col-2 token" *ngIf="kellnerums[user.key] !== undefinied">
                  {{user.umsatz}}€
                </div>
                <div class="col-2 token" *ngIf="kellnerums[user.key] !== undefinied">
                  {{user.key}}
                </div>
                <div class="col-3 name" *ngIf="kellnerums[user.key] !== undefinied">
                  {{user.vorname}} {{user.name}}
                </div>
                <div class="col icons" *ngIf="kellnerums[user.key] !== undefinied">
                  <i type="button" [ngClass]="{'bi bi-lock-fill lock': user.aktiv == 'false', 'bi bi-unlock-fill unlock': user.aktiv == 'true'}"(click)="lockKellner(user.aktiv,user.key)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-head">
          <h3>Benutzer Anlegen</h3>
        </div>
        <div class="card-body">
          <form [formGroup]="form"  (ngSubmit)="onSubmit()">
            <div class="input-group mb-3">
              <!-- <label for="Token" class="form-label">Token</label> -->

              <input type="email" placeholder="Token" aria-label="Username" aria-describedby="icon" class="form-control" formControlName="token" id="Token">
              <i type="button" (click)="newHash()" class="bi bi-arrow-clockwise input-group-text" id="icon"></i>
              <!-- <div id="emailHelp" class="form-text">automatisch generierter Token</div> -->
            </div>
            <div class="mb-3">
              <!-- <label for="vorname" class="form-label">Vorname</label> -->
              <input type="text" placeholder="Vorname" class="form-control" formControlName="vorname" id="vorname">
            </div>
            <div class="mb-3">
              <!-- <label for="name" class="form-label">Nachname</label> -->
              <input type="text" placeholder="Nachname" class="form-control" formControlName="name" id="name">
            </div>
            <div class="d-grid gap-2"><button type="submit" class="btn btn-danger">Submit</button></div>
          </form>
        </div>
      </div>
    </div>
  </div>

<!-- Button trigger modal -->
  <button type="button" class="btn btn-primary">
    Launch demo modal
  </button>


</div>

<div class="modal fade" id="exampleModal" data-bs-backdrop="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog-centered modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalHead">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <path class="elementor-shape-fill" d="M0,6V0h1000v100L0,6z"></path>
        </svg> -->
      </div>
      <div class="modal-body" id="modalBody">
        ...
      </div>
      <div class="modal-footer" id="modalFooter">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
